<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="1" md="1" sm="1" align-self="center">
        <GoBack link="/purchase/warehouse" />
      </v-col>

      <v-col cols="12" lg="11" md="11" sm="11" align-self="center">
        <v-card elevation="2">
          <v-breadcrumbs :items="items" divider="/">
            <template v-slot:[`item`]="{ item }">
              <v-btn
                v-if="item.back == true"
                link
                :to="item.to"
                text
                color="blue"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>

              <label v-else>
                {{ item.text }}
              </label>
            </template>
          </v-breadcrumbs>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.warehouseLog') + ' (A9 online)'"
          icon="mdi-apple-finder"
        >
          <v-row class="mt-0">
            <v-col cols="12" lg="3" md="3" sm="4">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                clearable
              />
            </v-col>

            <v-col cols="12" lg="3" md="3" sm="4">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                :nudge-top="30"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    :label="
                      $vuetify.lang.t('$vuetify.startDate') +
                      ' ~ ' +
                      $vuetify.lang.t('$vuetify.endDate')
                    "
                    prepend-inner-icon="mdi-calendar"
                    prepend-icon=""
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                  />
                </template>

                <v-date-picker
                  v-model="dates"
                  range
                  @change="handleDateChange"
                  @input="dates.length == 2 ? (menu = false) : true"
                  class="ma-0 pa-0"
                  :locale="
                    this.$vuetify.lang.current == 'zhHans' ? 'zh-cn' : 'en'
                  "
                />
              </v-menu>
            </v-col>

            <v-col cols="12" lg="3" md="3" sm="4">
              <v-select
                clearable
                :items="statuses"
                :label="$vuetify.lang.t('$vuetify.status')"
                return-object
                @change="(item) => handleStatusChange(item)"
              />
            </v-col>

            <span v-if="searchForm.type != ''" class="d-flex align-center">
              <v-chip
                v-if="searchForm.type == -1"
                color="red"
                dark
                class="display-1 font-weight-bold"
                >Stock Out</v-chip
              >
              <v-chip
                v-else
                color="blue"
                dark
                class="display-1 font-weight-bold"
                >Stock In</v-chip
              >
            </span>
          </v-row>

          <v-row>
            <v-col cols="12">
              <GeneralTable
                :head="headers"
                :data="datas"
                :search="search"
                :dialog.sync="dialog"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { warehousLog } from "@/api/purchase";

const newSearchForm = () => {
  return {
    skip: 0,
    limit: 500000,
    warehouse_id: "",
    goods_id: "",
    goods_type: 1, //1
    type: 0, //0
    bt: "",
    et: "",
  };
};

export default {
  name: "log",
  components: {
    GeneralTable: () => import("@/components/tableImg"),
    GoBack: () => import("@/components/gobackCard"),
  },
  data() {
    return {
      menu: false,
      dialog: false,
      dates: [],
      search: "",
      datas: [],
      statuses: [
        {
          value: -1,
          text: "Stock-Out",
        },
        {
          value: 1,
          text: "Stock-In",
        },
      ],
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.purchase") },
        { text: this.$vuetify.lang.t("$vuetify.warehouseLog") },
      ],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          width: "80",
          sortable: false,
          align: "center",
        },
        {
          text: "Delivery No.",
          value: "po",
          width: "100",
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.associatedContract"),
          value: "order_po",
          width: "140",
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.goodsName"),
          value: "goods_info.name",
          width: "300",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.productUnit"),
          value: "goods_info.unit",
          width: "100",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.category"),
          value: "category",
          width: "100",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.stockQty"),
          value: "change",
          width: "100",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.stockInOutRec"),
          value: "warehouse_info.name",
          width: "130",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.storageTime"),
          value: "GenTime",
          width: "140",
          sortable: false,
          align: "center",
        },
      ],
      searchForm: newSearchForm(),
    };
  },
  methods: {
    getData() {
      this.datas = [];

      warehousLog(this.searchForm)
        .then((data) => {
          console.log(data);

          if (data.items != null) {
            this.datas = [...data.items];

            this.datas.forEach((p, k) => {
              p.itemNo = k + 1;
              p.category = p.goods_info.type;
            });
          } else {
            this.$toast.info("No Data Found", { timeout: 1500 });
            this.datas = [];
          }
        })
        .catch((err) => console.log("Warehouse Log Error", err));
    },
    handleDialog(obj) {
      this.dialog = true;
      this.obj = obj;
    },
    BackToStockList() {
      this.$router.push("/purchase/warehouse");
    },
    handleDateChange() {
      this.searchForm.bt = Array.isArray(this.dates) ? this.dates[0] : "";
      this.searchForm.et = Array.isArray(this.dates) ? this.dates[1] : "";

      console.log(this.searchForm);

      this.getData();
    },
    handleStatusChange(item) {
      item != null
        ? (this.searchForm.type = item.value)
        : (this.searchForm.type = 0);

      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.searchForm.warehouse_id = this.$route.params.id;
  },
  computed: {
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (value) {
        if (value == null) {
          this.searchForm = newSearchForm();

          this.getData();
        }
      },
    },
  },
};
</script>